/* * {
	margin: 0;
} */

html,
body {
	height: 100%;
}

body > #root > div {
	min-height: 100vh;
}

#root {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto -20px;

	display: flex;
}

.top-navbar > * {
	margin: 0 10px;
}

a {
	color: black;
}

body {
	min-height: 100vh;
	/* display: flex; */
}

.root {
	display: "flex";
}

.dashboard input[type="checkbox"] {
	accent-color: green;
}

.dashboard input[type="checkbox"][disabled] {
	accent-color: inherit;
}

.dashboard {
	display: grid;
	grid-template-columns: 1fr 1fr 3fr;
	margin: 3%;
	gap: 50px;
	flex: 1;
}

.schedule-select {
	font-size: 12;
}

.schedule-select__control {
	width: 8rem;
	font-size: 14px;
	height: 30px;
}

.schedule-select__menu-list {
	width: auto;
	font-size: 14px;
}

div.schedule-select__group-heading,
div.schedule-select__option {
	padding: 0;
}

div.schedule-select__indicator {
	padding: 2px;
}

.vendor-settings input {
	max-width: 90%;
}

.top-navbar {
	font-size: 25px;
	padding: 10px 0;
	background-color: grey;
	width: 100%; /* Full width */
	left: 0;
	user-select: none;
}

.settings-nav {
	display: flex;
	flex-direction: column;
	font-size: larger;
	/* padding: 10px; */
	background-color: #cfcece;
	height: 100%;
	z-index: 1;
	/* width: 160px; Set the width of the sidebar */
	position: fixed; /* Fixed Sidebar (stay in place on scroll) */
	left: 0;
	user-select: none;
}

.settings-nav > div > div {
	padding: 10px 15px;
}

.settings-nav a,
.top-navbar a {
	text-decoration: none;
}

table.schedule-table {
	table-layout: fixed;
	border-spacing: 0;
}

.schedule-table th,
.schedule-table td {
	text-align: center;
}

.frozen-column {
	z-index: 10;
	position: sticky;
	left: 0;
	/* top: 0; */
	background-color: lightgray;
}

a:visited {
	color: inherit;
}

.display-block {
	display: block;
}

.solid-divider {
	border: none;
	border-top: solid 1px;
	width: 100%;
}

.download-link {
	text-decoration: none;
}

.download-link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.page {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.vendor-item {
	background-color: lightgray;
	padding: 10px;
	width: 500px;

	border-radius: 5px;
	margin: 10px 0px;
}

.vendor-item > h2 {
	margin-left: 10px;
}

.vendor-item > a,
a:visited {
	color: black;
}

.large-input {
	text-align: center;
	border: none;
	font-size: xx-large;
	font-weight: bold;
	margin: 20px 0;
	background-color: inherit;
}

.vendor-week-entry {
	text-align: center;
	width: 100%;

	background-color: lightgray;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0px;
}

.vendor-week-entry h3 {
	margin: 0;
}

.vendor-week-entry .solid-divider {
	margin: 10px 0;
}

/* .vendor-week-entry .vendor-table {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0.5rem;
} */

.vendor-table {
	border-spacing: 0;
}

.vendor-table th {
	font-weight: normal;
}

.panel {
	background-color: lightgray;
	padding: 10px;
	border-radius: 5px;
}

th,
td {
	padding: 2px 6px;
}

.dashboard-sales,
.dashboard-sales th,
.dashboard-sales td {
	/* border: 1px solid black;
	border-collapse: collapse;*/
	border-spacing: 0;
}

.dashboard-sales thead tr th {
	border-bottom: 1px solid black;
	padding-bottom: 5px;
}

.article-list {
	max-width: 60rem;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.article {
	display: grid;
	grid-template-columns: 1fr 1fr 0.2fr 1fr 1fr 0fr 1fr 1fr 0fr 1fr 1fr;
	gap: 0.5rem;
	margin: 10px 0px;

	background-color: lightgray;
	padding: 10px;
	border-radius: 5px;

	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

div.records-control {
	background-color: gray;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;
	padding: 0 10px;
	gap: 5px;
}

.table-with-fixed-head {
	overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
	max-height: 80vh; /* gives an initial height of 200px to the table */
}

.table-with-fixed-head thead th {
	position: sticky; /* make the table heads sticky */
	top: 0px; /* table head will be placed from the top of the table and sticks to it */
	background-color: lightgray;
}

.table-with-fixed-head thead tr th:first-child {
	z-index: 11;
}

.timeframe-selection {
	text-align: center;
}

.timeframe-selection td {
	min-width: 4rem;
}

.dashboard-sales input,
.dashboard-sales select {
	min-width: 90%;
	text-align: center;
}

div.react-datepicker-wrapper input {
	width: 100px;
	text-align: center;
}

.centering-div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.article-input {
	max-width: 4rem;
	border: none;
	border-bottom: solid 1px;
}

.vendor-item-input {
	max-width: 10rem;
	border: none;
	border-bottom: solid 1px;
}

.create-item {
	margin-top: 10px;
	padding: 10px;
}

.footer {
	min-height: 200px;
}

.modal {
	padding: 2rem;
	background-color: gray;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal > .header {
	width: 100%;
	border-bottom: 1px solid gray;
	font-size: 18px;
	text-align: center;
	padding: 5px;
}

.modal > .content {
	width: 100%;
	padding: 10px 5px;
}

.modal > .actions {
	width: 100%;
	padding: 10px 5px;
	text-align: center;
	display: block;
}

.modal > .actions > button {
	margin: 0 1rem;
}

.modal > .close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: #ffffff;
	border-radius: 18px;
	border: 1px solid #cfcece;
}

table.table-with-border,
.collapse-border {
	border-spacing: 0;
	border-collapse: collapse;
}

table.table-with-border td,
table.table-with-border th {
	border: 1px solid black;
}
